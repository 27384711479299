import React, { Component } from 'react';
import styled from 'react-emotion';

const Date = styled('h2')`
  margin: 0;
  text-align: center;
  font: bold 6rem 'Jost*';

  @media only screen and (orientation: landscape) and (max-width: 768px) {
    font: bold 4.0rem 'Jost*';
  }
`;

const Location = styled('h3')`
  margin: 0;
  text-align: center;
  font: 700 2.5rem 'Jost*';

  @media only screen and (orientation: landscape) and (max-width: 768px) {
    font: bold 2rem 'Jost*';
  }
`;

export default class Header extends Component {

  render() {
    return (
      <div>
        <Date>
          19
          <br />
          08
          <br />
          17
          <br />
        </Date>
        <a target="_blank" href="https://goo.gl/maps/s2cqmBUUbdS2">
          <Location>
            Elleholms kyrka
          </Location>
        </a>
      </div>
    );
  }
}
