import React, { Component } from 'react';
import styled from 'react-emotion';

import './layout.css';

/**
 * Component that alerts if you click outside of it
 */
const StyledButton = styled('button')`
  border: black;
  opacity: 0.8;
  margin: 5px;
  cursor: pointer;
  width: 80%;

  background: white;
  max-width: 70%;
  padding: 15px;
  border-radius: 15px;
  font: bold 1.8rem 'Jost*';

  //Phone landscape
  @media only screen and (orientation: landscape) {
    max-width: 31%;
    padding: 18px;
    border-radius: 15px;
    font: bold 1.5rem 'Jost*';
  }

  // Tablet portrait
  @media only screen and (orientation: portrait) and (min-width: 768px) {
    max-width: 45%;
    padding: 30px;
    margin: 10px;
    border-radius: 25px;
    font: bold 3.0rem 'Jost*';
  }

  // Tablet landscape
  @media only screen and (orientation: landscape) and (min-width: 768px) {
    max-width: 45%;
    padding: 30px;
    margin: 10px;
    border-radius: 25px;
    font: bold 3.0rem 'Jost*';
  }
`

export default class InformationButton extends Component {
  constructor(props) {
    super(props);

    this.showCorrespondingBox = this.showCorrespondingBox.bind(this);
  }

  showCorrespondingBox() {
    var style = document.getElementById(this.props.revealId).style;
    if (style.visibility == "hidden") {
        style.visibility = "visible";
        style.animation = "fadeIn 0.3s";
    }
  }

  render() {

    return (
        <StyledButton onClick={this.showCorrespondingBox}>
            {this.props.text}
        </StyledButton>
    );
  }
}


