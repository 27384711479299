import React, { Component } from 'react';

export default class Footer extends Component {

  render() {
    const divStyle = {
        height: this.props.height + 'px',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    };

    const pStyle = {
        color: '#FFF',
        margin: '0 0 3px 0',
        font: `normal 1.0rem 'Jost*'`
    };

    return (
        <div style={divStyle}>
            <p style={pStyle}>
                &copy; Kalle 2018
            </p>
        </div>
    );
  }
}


