import React from 'react'
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Parallax, ParallaxLayer } from 'react-spring'
import avatar from '../images/avatar.jpg';
import bg from '../images/bg.jpg';
import styled from 'react-emotion';

import Layout from '../components/layout';
import InformationOverlay from '../components/informationOverlay';
import InformationButton from '../components/informationButton';
import Footer from '../components/footer';
import Header from '../components/header';

export default class IndexPage extends React.Component{
  constructor() {
    super();
    this.state = {
      width: 500,
      height: 500
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  render() {
    const headerImgStyle = {
      height: 3.0 * this.state.height + 'px',
      margin: '0'
    };

    const headerOuterDivStyle = {
      width: '100%',
      height: 3 * this.state.height + 'px',
      padding: '0',
      overflow: 'visible',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexDirection: 'row',
    }

    const headerTextLayer = {
      position: 'absolute',
      padding: '0.2rem 0 0.7rem 0',
      background: '#FFF',
      color: '#444',
      opacity: '0.6',
      height: this.state.height + 'px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

    }

    const generalInformationTextLayer = {
      position: 'absolute',
      padding: '0.2rem 0 0.7rem 0',
      height: 'auto',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }

    const generalInformation = {
      margin: '0',
      font: 'normal 1.0rem sans-serif',
      textAlign: 'left',
      color: '#FFF'
    }

    const osaLayer = {
      position: 'absolute',
      padding: '0.2rem 0 0.7rem 0',
      mixBlendMode: 'lighten',
      height: 'auto',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }

    const informationButtonsOuterDivStyle = {
      width: '80%',
      height: this.state.height + 'px',
      overflow: 'visible',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }

    const informationButtonsInnerDivStyle = {
      margin: '0',
      padding: '0',
      flexFlow: 'row wrap',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }

    const loveStoryContainer = {
      width: '80%',
      height: this.state.height + 'px',
      maxHeight: this.state.height + 'px',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'center',
      overflow: 'scroll',
    }

    const LoveStoryInnerDiv = styled('div')`
      visibility: visible;
      height: ${0.8*this.state.height}px;
      text-align: left;
      font: normal 1.2rem 'Jost*';
      color: #FFF;
      overflow: scroll;

      // Tablet portrait
      @media only screen and (orientation: portrait) and (min-width: 768px) {
        visibility: visible;
      }
    
      // Tablet landscape
      @media only screen and (orientation: landscape) and (min-width: 768px) {
        visibility: visible;
      }
    `

    return(
      <Layout>
        <Parallax pages={3} scrolling={true} horizontal={false} ref={ref => this.parallax = ref}>
          <ParallaxLayer offset={0} speed={0}>
            <div style={headerOuterDivStyle}>
              <img src={bg} alt="background_image" style={headerImgStyle} onClick={() => this.parallax.scrollTo(1)}/>
            </div>
          </ParallaxLayer>
          <ParallaxLayer offset={0} speed={2} style={headerTextLayer}>
            <Header />
          </ParallaxLayer>
          <ParallaxLayer offset={1} speed={0.1} style={generalInformationTextLayer}>
            <div style={loveStoryContainer}>
              <LoveStoryInnerDiv>
                <p>Historien om Kalle och Frida började i augusti 2008 när vi började i klass NV08a på Väggaskolan. Vi hade aldrig pratat med varandra tidigare, men lärde via gemensamma kompisar snabbt känna varandra. Tiden gick och i mars 2009 blev vi ett par.</p>
                <p>Den första dejten var hemma hos Kalle där skräckfilmerna "Disturbia" och "Fritt Vilt" visades. Att titta på skräckfilmer visade sig vara ett genidrag av Kalle, för Frida var livrädd och ville inte se vad som hände. Detta vågade hon dock inte säga till Kalle, som istället trodde att Frida helt enkelt ville kramas väldigt mycket. Istället för att byta till en mer Fridavänlig film kramades Kalle tillbaka och skrattade. Kalle följde sedan upp filmsuccén med att bjuda på sin favoriträtt: ädelostsås med tortellini. Liksom vid filmen vågade blyga Frida inte heller säga att ädelost minsann inte var något som gemene man tyckte var så gott, utan hon satt plikttroget mittemot den stolta kocken Kalle och log och nickade när Kalle frågade hur gott det var.</p>
                <p>Under de tio år som gått som sedan denna exemplariska första dejt, har vi varit med om många strapatser och äventyr som har lärt oss ett och annat. Frida kan idag säga vad hon tycker och tänker och Kalle kan laga ... god mat även utan ädelost.</p>
                <p>På vår nioårsdag, den 6:e mars 2018, frågade Kalle om Frida ville ge sig ut på ett ännu större äventyr, ett äventyr som skulle börja med en vigsel i vårt kära Blekinge. Lyckligtvis var det den kvällen inte Kalle som stod bakom spisen, så Frida svarade ja.</p>
                <p>Det första äventyret inledde vi omgivna av vänner i vår dåvarande gymnasieklass. Vårt nästa äventyr vill vi inleda med de vänner som nu är i vår omgivning. Därför vill vi jättegärna att ni kommer till vackra Elleholm i Blekinge den 17:e augusti 2019 och bevittnar vår vigsel.</p>
              </LoveStoryInnerDiv>
            </div>
          </ParallaxLayer>
          <ParallaxLayer offset={2.0} speed={0.2}>
            <Footer height={this.state.height}/>
          </ParallaxLayer>
          <ParallaxLayer offset={2.0} speed={0.1} style={osaLayer}>
            <div style={informationButtonsOuterDivStyle}>
              <div style={informationButtonsInnerDivStyle}>
                <InformationButton text="OSA" revealId="infoBoxOsa"/>
                <InformationButton text="Plats" revealId="infoBoxLocation"/>
                <InformationButton text="Upptåg" revealId="infoBoxEscapades"/>
                <InformationButton text="Boende" revealId="infoBoxAccomodation"/>
                <InformationButton text="Klädkod" revealId="infoBoxDressCode"/>
                <InformationButton text="Presenter" revealId="infoBoxGifts"/>
              </div>
            </div>
          </ParallaxLayer>
        </Parallax>
        
        <InformationOverlay id="infoBoxOsa" height={this.state.height} width={this.state.width}>
          <p className='informationOverlay'>
						Datumet för OSA har passerat. Stort tack till alla för att ni anmälde er i god tid. Nu har vi stenkoll på vilka som kommer :)
          </p>
        </InformationOverlay>

        <InformationOverlay id="infoBoxDressCode" height={this.state.height} width={this.state.width}>
          <p className='informationOverlay'>Det finns många skolor kring hur man "bör" klä sig på ett bröllop, vilket vi tycker är förvirrande. Vi har därför valt att inte ha någon uttalad klädkod, utan istället skrivit ner några förslag på kläder som vi tycker passar, och på kläder som bör hänga kvar i garderoben.</p>
          <br />
          <p className='informationOverlay'>Vi litar på våra gäster, så utgå från våra förslag och klä dig i något som du tycker passar och som du är bekväm i. Det är inte kläderna som gör gästen, det är gästen som gör kläderna!</p>
          <br />
          <p className='informationOverlay'>Kläder som passar:</p>
          <ul>
            <li>byxdress</li>
            <li>chinos</li>
            <li>kavaj</li>
            <li>kjol</li>
            <li>klänning</li>
            <li>kostym</li>
            <li>skjorta</li>
          </ul> 
          <p className='informationOverlay'>Kläder som bör hänga kvar i garderoben:</p>
          <ul>
            <li>jeans</li>
            <li>kortbyxor</li>
            <li>luvtröja</li>
            <li>löparskor</li>
            <li>mjukisbyxor</li>
            <li>träningskläder</li>
            <li>t-shirt</li>
          </ul> 
          <p className='informationOverlay'>Klassiska accessoarer såsom slips och fluga är såklart välkommet, dock endast i kombination med andra kläder...</p>

        </InformationOverlay>

        <InformationOverlay id="infoBoxGifts" height={this.state.height} width={this.state.width}>
          <p className='informationOverlay'>Vi tycker båda om att få presenter, men på vårt bröllop undanber vi oss hårda paket. Däremot blir vi jätteglada för ett bidrag till vår bröllopsresa som går till ön Guadeloupe i Karibien.</p>
          <br />
          <p className='informationOverlay'>För den som vill ge ett bidrag i form av ett presentkort så kan det köpas genom att ringa till vår resebyrå Langley Travel på <a href="tel:+46771121212">0771-12 12 12</a>. Notera att det inte går att köpa presentkorten via deras hemsida. (Det går självklart bra att ge oss ett bidrag även på andra sätt än presentkort.)</p>
        </InformationOverlay>

        <InformationOverlay id="infoBoxAccomodation" height={this.state.height} width={this.state.width}>
          <p className='informationOverlay'></p>
          <p className='informationOverlay'>För långväga gäster finns gott om möjligheter till övernattning. För att göra det enkelt att boka har vi listat några bra alternativ nedan.</p>
          <br />
          <p className='informationOverlay'>Kontakta oss gärna om ni vill ha mer information!</p>
          <br />
          <h2 style={{textAlign: 'center', textDecoration: 'underline', marginTop: '0'}}>
            <a href="http://www.morrum.se" target="_blank" rel="noopener noreferrer">Mörrum River Lodge</a>
          </h2>
          <h2 style={{textAlign: 'center', textDecoration: 'underline', marginTop: '0'}}>
            <a href="http://www.hotelwalhalla.se/sv/" target="_blank" rel="noopener noreferrer">Hotell Walhalla</a>
          </h2>
          <h2 style={{textAlign: 'center', textDecoration: 'underline', marginTop: '0'}}>
            <a href="https://www.firsthotels.se/hotell/sverige/karlshamn/first-hotel-carlshamn/" target="_blank" rel="noopener noreferrer">First Hotel</a>
          </h2>
          <h2 style={{textAlign: 'center', textDecoration: 'underline', marginTop: '0'}}>
            <a href="https://www.bestwesternkarlshamn.se/" target="_blank" rel="noopener noreferrer">Best Western</a>
          </h2>
          <h2 style={{textAlign: 'center', textDecoration: 'underline', marginTop: '0'}}>
            <a href="http://kollevikscamping.se/" target="_blank" rel="noopener noreferrer">Kolleviks Camping</a>
          </h2>
          <h2 style={{textAlign: 'center', textDecoration: 'underline', marginTop: '0'}}>
            <a href="http://porthotel.se" target="_blank" rel="noopener noreferrer">Port Hotel</a>
          </h2>
        </InformationOverlay>

        <InformationOverlay id="infoBoxLocation" height={this.state.height} width={this.state.width}>
          <p className='informationOverlay'>Vigseln kommer ske klockan 15:00 i Elleholms kyrka i Elleholm utanför Mörrum.</p>
          <br />
          <p className='informationOverlay'>Bröllopsfesten kommer därefter att vara i vår alldeles egna festlokal på Hästarydsgården i Mörrum.</p> 
          <br />
          <h2 style={{textAlign: 'center', textDecoration: 'underline', marginTop: '0'}}>
            <a href="https://goo.gl/maps/k6iTUSBaHp42" target="_blank" rel="noopener noreferrer">Elleholms kyrka</a>
          </h2>
          <h2 style={{textAlign: 'center', textDecoration: 'underline', marginTop: '0'}}>
            <a href="https://www.google.se/maps/dir/Elleholms+Kyrka,+Elleholmsvägen,+375+30+Mörrum/Enebacken+8,+Mörrum/" target="_blank" rel="noopener noreferrer">Vägbeskrivning från Elleholms kyrka till Hästarydsgården</a>
          </h2>
        </InformationOverlay>

        <InformationOverlay id="infoBoxEscapades" height={this.state.height} width={this.state.width}>
          <p className='informationOverlay'>Alla former av upptåg under festen uppskattas. Sång/film/tal, ja allt ni kan komma på. För att kunna planera kvällens fest och fixa eventuell utrustning som behövs, vill våra toastmasters Johan Björk (mobil: <a href="tel:+46704762621">070-476 26 21</a>) och Fredrik Ring (mobil: <a href="tel:+46722319982">072-231 99 82</a>) känna till era upptåg innan festen.</p>
          <br />
          <p className='informationOverlay'>Anmäl era upptåg via följande länk:</p>
          <br />
          <h2 style={{textAlign: 'center', textDecoration: 'underline', marginTop: '0'}}>
            {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSdoBtGLzByy-6x84ZuiGlBVdMD3-7TGSIKbPP40mvHI_RlV8g/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">Anmäl upptåg</a> */}
            <a href="http://upptag.kfsvensson.se" target="_blank" rel="noopener noreferrer">Anmäl upptåg</a>
          </h2>
          <p className='informationOverlay'>PS. Det är bara toastmasters som har tillgång till era svar. DS.</p>
        </InformationOverlay>
      </Layout>
    );
  }
}


export const pageQuery = graphql`
  query HeaderImageQuery {
    file(relativePath: {eq: "images/avatar.jpg"}) {
      childImageSharp {
        sizes(maxWidth: 200) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`