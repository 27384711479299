import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { keyframes } from 'react-emotion';

import './layout.css';

const fadeOut = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: hidden;
  }
`
/**
 * Component that alerts if you click outside of it
 */
export default class InformationOverlayBox extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      var style = document.getElementById(this.props.id).style;
      if (style.visibility == "visible" && style.opacity == '1') {
        style.animation = "fadeOut 0.3s";
        setTimeout(() => {
          style.visibility = "hidden";
        }, 270);
      }
    }
  }

  render() {
    const divStyle = {
      background: '#fff',
      opacity: '1',
      border: '#000',
      borderRadius: '15px',
      width: 0.75*this.props.width + 'px',
      maxWidth: 0.75 * 960 + 'px',
      height: 'auto',
      maxHeight: 0.8*this.props.height + 'px',
      overflow: 'scroll',
      padding: "15px",
      color: '#000',
      font: `normal 1.2rem 'Jost*'`,
      animationFillMode: 'forwards',
    };

    return (
        <div ref={this.setWrapperRef} style={divStyle}>
            {this.props.children}
        </div>
    );
  }
}
