import React, { Component } from 'react';

import InformationOverlayBox from './informationOverlayBox';

/**
 * Component that alerts if you click outside of it
 */
export default class InformationOverlay extends Component {

  render() {
    const divStyle = {
      background: 'rgba(0, 0, 0, 0.6)',
      opacity: '1',
      position: 'absolute',
      width: 1.0*this.props.width + 'px',
      height: 1.0*this.props.height + 'px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      visibility: 'hidden',
      animationFillMode: 'forwards',
    };

    return (
        <div id={this.props.id} style={divStyle}>
            <InformationOverlayBox {... this.props}>
                {this.props.children}
            </InformationOverlayBox>
        </div>
    );
  }
}

